import { Media } from 'src/app/models/common.model';
import { Component, OnInit, Input } from '@angular/core';
import { SuccessStories } from 'src/app/models/success-stories.model';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  @Input() success: SuccessStories[];
  @Input() background: Media;
  constructor() { }

  ngOnInit() {}

}
