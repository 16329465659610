import { MediaAdapter } from './../../models/common.model';
import { PageAboutUs } from './../../models/page-about-us.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageAboutUsService } from 'src/app/services/page-about-us.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Media } from 'src/app/models/common.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


const pageId:string = '5OmdcpyvKEvroAI2iVGFVE';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent implements OnInit {
  showProgressBar = true;
  aboutUsData: PageAboutUs;
  video: SafeHtml = "";
  constructor(
    private pageAboutUsService: PageAboutUsService,
    public sanitizer: DomSanitizer
  ) { }

  getSafeHtml(string?: string) {
    return this.sanitizer.bypassSecurityTrustHtml(string);
}

  ngOnInit() {
    const options = {
      renderNode: {
        'embedded-asset-block': (node: any) => {
          let mediaAdapter:MediaAdapter = new MediaAdapter();
          let img:Media = mediaAdapter.adapt(node.data.target.fields);
          const imgTag = `<img class="img-fluid" src="${img.file.url}" alt="${img.title}"/>`;
          return imgTag;
        }
      }
    }

    this.pageAboutUsService.getPage(pageId).subscribe((aboutUs: PageAboutUs) => {
      this.video = this.getSafeHtml(`<iframe width="560" height="315" src="${aboutUs.video}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`);
      this.showProgressBar = false;
      this.aboutUsData = aboutUs;
      this.aboutUsData.aboutUs = documentToHtmlString(this.aboutUsData.aboutUs, options);
      this.aboutUsData.ourPurpose = documentToHtmlString(this.aboutUsData.ourPurpose, options);
    })
  }
}
