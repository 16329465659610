import { Highlights, HighlightsAdapter } from './highlights.model';
import { OurPhilosophy, OurPhilosophyAdapter } from './our-philosophy.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { Media } from './common.model';

export class PageAboutUs {
    constructor(
        public highlightHeadRef: Highlights[],
        public video: string,
        public aboutUs: any,
        public ourPurpose: any,
        public ourPhilosophy: OurPhilosophy[],
        public aboutUsLabel: string,
        public ourPurposeLabel: string,
        public ourPhilosophyLabel: string,
        public contactSectionTitle: string,
        public contactSectionContactSubtitle: string,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PageAboutUsAdapter implements Adapter<PageAboutUs> {

  adapt(item: any): PageAboutUs {
    let ourPhilosophyAdapter = new OurPhilosophyAdapter();
    let highlightsAdapter = new HighlightsAdapter();

    return new PageAboutUs(
      item.highlightHeadRef.map((highlight: any) => highlightsAdapter.adapt(highlight.fields)),
      item.video,
      item.aboutUs,
      item.ourPurpose,
      item.ourPhilosophy.map((ourPhilosophy: any) => ourPhilosophyAdapter.adapt(ourPhilosophy.fields)),
      item.aboutUsLabel,
      item.ourPurposeLabel,
      item.ourPhilosophyLabel,
      item.contactSectionTitle,
      item.contactSectionContactSubtitle
    );
  }
}