import { Media } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class ClientExperience {
    constructor(
        public fullName: string,
        public feedbackDesc: string,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class ClientExperienceAdapter implements Adapter<ClientExperience> {

  adapt(item: any): ClientExperience {
    return new ClientExperience(
      item.fullName,
      item.feedbackDesc,
    );
  }
}