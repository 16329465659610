import { options } from './common.model';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { CategorySolutions, CategorySolutionsAdapter } from './category-solutions.model';
import { Highlights, HighlightsAdapter } from './highlights.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class PageProductServices {
    constructor(
        public highlightsHead: Highlights[],
        public categorySolutions: CategorySolutions[],
        public textoContacto: string,
        public bajadaContacto: string,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PageProductServicesAdapter implements Adapter<PageProductServices> {

  adapt(item: any): PageProductServices {
    let highlightsAdapter = new HighlightsAdapter();
    let categorySolutionsAdapter = new CategorySolutionsAdapter();
    return new PageProductServices(
      item.highlightsHeadRef.map((highlight: any) => highlightsAdapter.adapt(highlight.fields)),
      item.categorySolutions.map((category: any) => {
        if (typeof category !== 'undefined' && !category.fields) return false; 
        return categorySolutionsAdapter.adapt(category.fields)
      }),
      item.textoContacto !== 'undefined' ? documentToHtmlString(item.textoContacto, options) : "",
      item.bajadaContacto !== 'undefined' ? documentToHtmlString(item.bajadaContacto, options) : "",
    );
  }
}