import { Media, options } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export class Highlights {
    constructor(
        public headerSectionTeaserLabel: string,
        public headerSectionShortDesc: any,
        public highlightsObject: Media,
        public typeObject: string,
        public url: string,
        public buttonContact: boolean,
        public videoSource: Media,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class HighlightsAdapter implements Adapter<Highlights> {

  adapt(item: any): Highlights {
    return new Highlights(
      item.tituloHightlight,
      item.headerSectionShortDesc !== 'undefined' ? documentToHtmlString(item.headerSectionShortDesc, options) : "",
      item.typeObject === 'image' ? item.highlightsObject.fields : item.highlightVideoYoutube,
      item.typeObject,
      item.url,
      item.buttonContact,
      // item.videoSource.fields
      typeof item.videoSource !== 'undefined' ? item.videoSource.fields : ""
    );
  }
}