import { Configuration } from './../../models/configuration.model';
import { ShareDataService } from './../../services/share-data.service';
import { ConfigurationService } from './../../services/configuration.service';
import { SocialMedia } from './../../models/social-media.model';
import { Component, OnInit, OnChanges } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const pageId = '34VMV1aXjQ0WegenhXm4nw';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit {
  socialMedia: SocialMedia[];
  configurationData: Configuration;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    
  }
  
  ngOnInit() {}

}