import { PageAboutUs, PageAboutUsAdapter } from './../models/page-about-us.model';
import { ContentService } from './content.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PageAboutUsService {
  constructor(private contentService: ContentService, private adapter: PageAboutUsAdapter) { }
  getPage(pageId?:string): Observable<PageAboutUs> {
    return this.contentService.getEntry(pageId).pipe(
      map(data => this.adapter.adapt(data.fields)));
  }
}