import { AppConfig } from './../../config/app.config';
import { EmailService } from './../../services/email.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Configuration } from 'src/app/models/configuration.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {
  @ViewChild('f') signupForm: NgForm;
  @Input() negativo:boolean = false;
  submitted = false;
  contact = {
    nameLastName : '',
    email : '',
    message :'',
    
  };

  configurationData:Configuration;
  constructor(
    private emailService: EmailService,
    private config: AppConfig
  ) { }

  ngOnInit() {
    this.configurationData = this.config.getConfig();
  }
  
  onSubmit(){
    this.contact.nameLastName = this.signupForm.value.nameLastName;
    this.contact.email = this.signupForm.value.email;
    this.contact.message = this.signupForm.value.message;
    let usuario = `<tr><td style=\"padding: 5px; border: 1px solid #009CDE;\">Nombre</td><td style=\"padding: 5px; border: 1px solid #009CDE;\">${this.contact.nameLastName}</td></tr>`;
    let email = `<tr><td style=\"padding: 5px; border: 1px solid #009CDE;\">Email</td><td style=\"padding: 5px; border: 1px solid #009CDE;\">${this.contact.email}</td></tr>`;
    let message = `<tr><td style=\"padding: 5px; border: 1px solid #009CDE;\">Mensaje</td><td style=\"padding: 5px; border: 1px solid #009CDE;\">${this.contact.message}</td></tr>`;
    let content = `${usuario}${email}${message}`;
    let data = {
      "templateName": "contact_form",
      "sendTo": this.configurationData.correoContacto,
      "templateData": {
        "LOGO": environment.emailConfig.logo,
        "ASUNTO": `${this.contact.nameLastName} se contactó, a continuación adjuntamos los datos`,
        "NOMBRE": this.contact.nameLastName,
        "CONTENT": content
      }
    }

    let dataUser = {
      "templateName": "contact_form",
      "sendTo": this.contact.email,
      "templateData": {
        "LOGO": environment.emailConfig.logo,
        "ASUNTO": `${this.contact.nameLastName}. <br />Gracias por contactarnos, acá adjuntamos los datos que ajuntaste`,
        "NOMBRE": this.contact.nameLastName,
        "CONTENT": content
      }
    }
    this.emailService.sendMessage(data).subscribe((resp) => {
      this.emailService.sendMessage(dataUser).subscribe((resp) => {
        console.log(resp);
        this.submitted = true;
      })
      
    })
    this.signupForm.reset();
  }

}
