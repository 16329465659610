import { BusinessArea } from './../../models/business-area.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Owners } from './../../models/owners.model';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OwnersComponent implements OnInit {
  @Input() ownersData: Owners[];
  @Input() tituloRepresentantes: string;
  @Input() subtituloRepresentantes: string;
  @Input() selector = false;
  @Input() businessAreas: BusinessArea[];
  currentOwner: Owners = null;
  showContact = false;
  isDesktopDevice:boolean = true;
  currentBusinessArea: BusinessArea;

  constructor(
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.isDesktopDevice = this.deviceService.isDesktop();
    if (this.selector){
      this.currentBusinessArea = this.businessAreas[0];
    }
    
  }

  agendarReunion(owner:Owners) {
    this.showContact = true;
    this.currentOwner = owner;
  }

  closeAgendarReunion(event: any) {
    this.showContact = false;
    this.currentOwner = null;
    
    
  }

  selectBusiness(selectedBusiness: BusinessArea) {
    this.currentBusinessArea = selectedBusiness;
  }

}
