<div id="content">
  <!-- / Progress Bar -->
  <ng-container>
    <mat-progress-bar *ngIf="showProgressBar" color="primary" mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <!-- / Progress Bar -->
  <ng-container>
    <ng-container *ngIf="aboutUsData">
      <app-highlights [highlights]="aboutUsData.highlightHeadRef" [home]="false"></app-highlights>
    </ng-container>
    <section class="nosotros mat-typography pt-lg-0 pb-lg-5" *ngIf="aboutUsData">
      <div class="container quienes d-flex flex-column pt-lg-5 mt-3 mb-3" *ngIf="aboutUsData">
        <div class="title__wrapper title__wrapper-black">
          <h2 class="mb-lg-3 title__wrapper-title blue">{{aboutUsData.aboutUsLabel}}</h2>
        </div>
        <div class="content d-flex flex-fill flex-lg-row flex-column">
          <div [innerHTML]="aboutUsData.aboutUs"></div>
          <div class="float-right ml-lg-4 img-fluid" [innerHTML]="video"></div>
        </div>
      </div>
      <div class="container proposito d-flex flex-column pt-lg-5 mt-3 mb-3" *ngIf="aboutUsData">
        <div class="title__wrapper title__wrapper-black">
          <h2 class="mb-lg-3 title__wrapper-title blue">{{aboutUsData.ourPurposeLabel}}</h2>
        </div>
        <div class="content d-flex flex-fill">
            <div [innerHTML]="aboutUsData.ourPurpose"></div>
        </div>
      </div>
      <div class="container-lg filosofia d-flex flex-column mt-5 mb-3 align-items-center p-0" *ngIf="aboutUsData">
        <div class="title__wrapper title__wrapper-black">
          <h2 class="mb-lg-3 title__wrapper-title blue">{{aboutUsData.ourPhilosophyLabel}}</h2>
        </div>
        <div class="row box-container">
          <div class="col-lg-4 col-10 col-md-8" *ngFor="let items of aboutUsData.ourPhilosophy">
            <div class="item row h-100 m-0 p-0">
              <div class="col-12 my-auto">
                <h5 class="d-block">{{items.title}}</h5>
                <p class="pt-lg-4 pt-0">{{items.shortDescription}}</p>
              </div><!-- /col-lg-12 col-8 -->
            </div><!-- /row -->
          </div><!-- /.col-lg-4 -->
        </div><!-- row -->
      </div>
      
    </section>
  </ng-container>
  <section class="title__wrapper title__wrapper-cyan pb-5 transformamos__title mt-lg-5 mt-4" *ngIf="aboutUsData">
    <div class="p-lg-5 text-center mat-typography container w-80">
      <h2 class="title__wrapper-title w-75 text-center mx-auto">{{aboutUsData.contactSectionTitle}}</h2>
    </div>
  </section>
  <ng-container>
    <div class="container mt-lg-3">
      <app-contact [negativo]="true"></app-contact>
    </div><!-- /container -->
    <div class="nosotros container mt-5 mb-lg-0 mb-5 pt-lg-5" *ngIf="aboutUsData">
      <div class="title__wrapper title__wrapper-red">
        <h2 class="mb-lg-5 title__wrapper-title w-75 mx-auto text-center">{{aboutUsData.contactSectionContactSubtitle}}</h2>
      </div>
    </div>
  </ng-container>
</div>