<div id="content">
  <!-- / Progress Bar -->
  <ng-container>
    <mat-progress-bar *ngIf="showProgressBar" color="primary" mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <!-- / Progress Bar -->
  <ng-container>
    <ng-container *ngIf="homeData">
      <app-highlights [highlights]="homeData.highlightsHeadRef"></app-highlights>
    </ng-container>
    <section class="title__wrapper title__wrapper-blue-icon" *ngIf="homeData">
      <div class="p-lg-5 p-4 text-center mat-typography container">
        <h2 class="title__wrapper-title">{{homeData.categoryServicesTitle}}</h2>
      </div>
    </section>
    <section class="products pb-lg-0 pb-0" *ngIf="homeData">
      <div class="products__container mat-typography px-lg-5 pt-lg-5 pb-lg-4 pb-3 container">
        <app-category-solutions [categorySolutions]="homeData.categorySolutions"></app-category-solutions>
        <div class="mt-4 mt-lg-2 text-right more"><!-- more -->
          <h5 class="display-5 justify-content-end mb-lg-0">
            <a routerLink="/product-services" class="d-flex animated">
              Conoce todos nuestros productos y soluciones
              <span class="material-icons ml-2">
                keyboard_arrow_right
                </span>
            </a>
          </h5>
        </div>
        <!-- more -->
      </div><!-- products__container -->
    </section><!-- products -->
    <section class="industries__title mt-5 title__wrapper" *ngIf="homeData"><!-- title__wrapper -->
      <div class="px-lg-5 pt-lg-5 pb-lg-0 mt-lg-0 mt-4 text-center mat-typography container mb-lg-0 mb-4">
        <div class="title__wrapper title__wrapper-black">
          <h2 class="title__wrapper-title">{{homeData.industriesTitle}}</h2>
        </div>
      </div>
    </section>
    <!-- title__wrapper -->
    <ng-container *ngIf="homeData">
      <app-industries [industries]="homeData.industries"></app-industries>
    </ng-container><!-- insdustries -->
    <ng-container *ngIf="homeData">
      <section class="mt-5 title__wrapper industria__contactanos d-flex justify-content-center flex-column p-lg-0 py-5 px-3" [style.background]="configurationData ? 'url(' + configurationData.backgroundContact.file.url + ')' : ''"><!--industria__contactanos -->
        <!-- <div class="transparency"></div> -->
        <div class="text-center mat-typography container">
          <div class="title__wrapper">
            <div class="title__wrapper-title" [innerHTML]="homeData.industriesNotVisibleSectionText"></div>
            <button mat-raised-button color="primary" class="mt-4 text-uppercase py-1" [ngx-scroll-to]="'contact'">Contáctanos</button>
          </div>
        </div>
      </section>
    </ng-container>
    <!-- industria__contactanos -->
    <section class="mt-5 success__title title__wrapper my-3" *ngIf="homeData"><!-- title__wrapper -->
      <div class="p-lg-5 text-center mat-typography container">
        <div class="title__wrapper">
          <h2 class="title__wrapper-title">{{homeData.successHistoriesLabel}}</h2>
        </div>
      </div>
    </section>
    <ng-container *ngIf="homeData">
      <app-success [success]="homeData.successStories" [background]="configurationData ? configurationData.backgroundSuccess: null"></app-success>
    </ng-container>
    <section class="title__wrapper apariciones__title" *ngIf="homeData"><!-- title__wrapper -->
      <div class="mt-5 p-lg-5 text-center mat-typography container">
        <div class="title__wrapper">
          <h2 class="title__wrapper-title text-left">{{homeData.mediaAppearances.title}}</h2>
        </div>
      </div>
    </section>
    <section class="apariciones pb-lg-5 py-lg-5 pt-0 pb-5" *ngIf="homeData">
      <div class="apariciones__container mat-typography container">
        <div class="row mb-2 mb-lg-5">
          <div class="col-lg-4 col-12 d-flex justify-content-center" *ngFor="let items of homeData.mediaAppearances.medios">
            <div class="aparicion__logo p-4 text-center">
              <img src={{items.file.url}} class="img-fluid" *ngIf="items.file" />
            </div>
          </div><!-- /.col-lg-4 -->
        </div><!--/row-->
        <h2 class="mt-2 mt-lg-4 text-center">{{homeData.mediaAppearances.description}}</h2>
      </div><!--/apariciones__container -->
    </section>
    <section class="recomendations py-lg-5 py-0" *ngIf="homeData">
      <div class="recomendations__container container mat-typography d-flex justify-content-center flex-wrap">
        <div class="col-lg-4 col-12 py-lg-0 py-5" *ngFor="let items of homeData.clientExperience">
          <p>
            “{{items.feedbackDesc}}”
          </p>
          <h5>{{items.fullName}}</h5>
        </div>
      </div>
    </section>
    <section class="blog p-lg-5 py-5" *ngIf="homeData">
      <div class="blog__container mat-typography container d-flex justify-content-center flex-column">
        <h2 class="mt-0 mt-lg-4 align-self-center text-center">{{homeData.blogTitleSection}}</h2>
        <div class="row d-flex justify-content-center my-lg-5 my-2">
          <div class="col-lg-4 col-12 d-flex justify-content-center" *ngFor="let items of homeData.blogs">
            <mat-card class="blog__card m-lg-0 my-2">
              <div class="image_container">
                <a href={{items.urlMedium}} target="_blank">
                  <img mat-card-image src={{items.teaserImage.file.url}} alt={{items.teaserImage.title}} *ngIf="items.teaserImage">
                </a>
              </div>
              <mat-card-content class="px-2">
                <h3>
                  <a href={{items.urlMedium}} target="_blank">
                    {{items.titleArticle}}
                  </a>
                </h3>
                <p>
                  <a href={{items.urlMedium}} target="_blank">
                    {{items.shortDescription}}
                  </a>
                </p>
              </mat-card-content>
              <mat-card-actions class="d-flex justify-content-end">
                <a mat-button href={{items.urlMedium}} target="_blank">Ver mas</a>
              </mat-card-actions>
            </mat-card>
          </div><!-- /.col-lg-4 -->
        </div><!--/row-->
      </div><!--/blog__container -->
    </section>
    <section class="title__wrapper title__wrapper-cyan pb-5 transformamos__title">
      <div class="p-lg-5 text-center mat-typography container w-80">
        <h2 class="title__wrapper-title">Transformamos tu negocio con innovación y tecnología de punta ajustandonos a tus necesidades. </h2>
      </div>
    </section>
    <ng-container>
      <div class="container pb-lg-0 pb-5">
        <app-contact [negativo]="true"></app-contact>
      </div>
    </ng-container>
  </ng-container>
</div> <!-- end content -->