<div class="success d-flex align-items-center background" [style.background]="background ? 'url(' + background.file.url + ')' : ''"><!-- success -->
  <div class="success__container container"><!-- success__container -->
    <div class="items row mat-typography"><!-- items -->
      <div class="case col-lg-4 col-12 p-lg-0 m-lg-0 my-2" *ngFor="let items of success"><!-- case -->
        <div class="align-items-center justify-content-center d-lg-flex flex-column case__inner py-2"><!-- case__inner -->
          <h2 class="mb-4 mt-5">
            {{items.title}}
          </h2>
          <p class="px-2 mt-1 mb-5 text-center">
            {{items.longDescription}}
          </p>
          <div class="button-container mt-5 mb-5"><!-- button-container -->
            <button mat-mini-fab color="primary" aria-label="Example icon button with a delete icon">
              <mat-icon>add</mat-icon>
            </button>
          </div><!-- /button-container -->
        </div><!-- /case__inner -->
      </div><!-- /case -->
    </div><!-- /items -->
  </div><!-- /success__container -->
</div><!-- /success -->