export const environment = {
  production: true,
  emailConfig: {
    server: 'https://rvru8y86yk.execute-api.us-east-1.amazonaws.com/dev',
    apiKey: 'Bp4JVLp6JW1cBAy5pSRce5CI2GONWwpQ8AREqB6c',
    logo: 'https://enteloceanportal.2brains.cl/assets/logo.svg'
  },
  contentful: {
    space: '14f3uy02pheo',
    accessToken: 'xMZPK0e3bK-asVJIQhlVJc_e87gZikziQdr0oStXsTo',
    host: 'cdn.contentful.com',
    environment: 'master',
    removeUnresolved: true
  },
};