import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import { Observable, from } from 'rxjs';
import {environment} from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContentService {

  private client = contentful.createClient({
    space: environment.contentful.space,
    accessToken: environment.contentful.accessToken,
    host: environment.contentful.host,
    environment: environment.contentful.environment,
    removeUnresolved: false,
    resolveLinks: true
  });

  constructor() { }

  getEntry(id?: string): Observable<any> {
    const promise = this.client.getEntry(id);
    return from(promise)
      .pipe(
        map(entry => entry)
      );
  }

  getContent(query?: object): Observable<any> {
    const promise = this.client.getEntries(query);
    return from(promise)
      .pipe(
        map(entry => entry)
      );
  }
}