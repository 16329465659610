import { Media } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class Owners {
    constructor(
        public fullName: string,
        public profilePhoto: Media,
        public position: string,
        public email: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class OwnersAdapter implements Adapter<Owners> {

  adapt(item: any): Owners {
    return new Owners(
      item.fullName,
      item.profilePhoto.fields,
      item.position,
      item.email,
    );
  }
}