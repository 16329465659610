import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../internals/shared.module';
import { HomeComponent } from '../pages/home/home.component'
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { MainNavigationComponent } from '../pages/main-navigation/main-navigation.component';
import { MainModule } from '../components/main.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { PagePartnersComponent } from './page-partners/page-partners.component';
import { PageCareersComponent } from './page-careers/page-careers.component';
import { PageProductosServiciosComponent } from './page-productos-servicios/page-productos-servicios.component';
import { PageIndustriesComponent } from './page-industries/page-industries.component';
import { InteriorSolucionComponent } from './interior-solucion/interior-solucion.component';

@NgModule({

  imports: [
    RouterModule,
    SharedModule,
    MainModule
  ],

  declarations: [
    MainNavigationComponent,
    PageNotFoundComponent,
    HomeComponent,
    AboutUsComponent,
    PagePartnersComponent,
    PageCareersComponent,
    PageProductosServiciosComponent,
    PageIndustriesComponent,
    InteriorSolucionComponent,
  ],

  exports: [
    PageNotFoundComponent,
    MainNavigationComponent,
    HomeComponent,
    AboutUsComponent,
    PagePartnersComponent,
    PageCareersComponent,
    PageProductosServiciosComponent,
    PageIndustriesComponent,
    InteriorSolucionComponent,
  ]

})
export class PagesModules { }