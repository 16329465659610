import { Highlights } from './../../models/highlights.model';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HighlightsComponent implements OnInit {
  @Input() home:boolean = true;
  @Input() highlights: Highlights[]; 
  items:Highlights[] = [];
  customOptions: OwlOptions;


  constructor() { }

  ngOnInit() {
    if (this.highlights){
      this.highlights.map((highlight) => {
        if (highlight) this.items.push(highlight);
      })
    }
    //this.items = this.highlights;
    
    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      autoHeight: true,
      items: this.items.length,
      autoplayHoverPause: true,
      autoplay: true,
      autoplaySpeed: 1500,
      autoplayTimeout: 5000,
      navText: ['<span class="material-icons">arrow_back_ios</span>', '<span class="material-icons">arrow_forward_ios</span>'],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 1
        },
        740: {
          items: 1
        },
        940: {
          items: 1
        }
      },
      nav: true
    }
  }

}