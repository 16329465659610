import { AppConfig } from './../../config/app.config';
import { Configuration } from './../../models/configuration.model';
import { CategorySolutionsService } from './../../services/category-solutions.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategorySolutions } from 'src/app/models/category-solutions.model';

@Component({
  selector: 'app-interior-solucion',
  templateUrl: './interior-solucion.component.html',
  styleUrls: ['./interior-solucion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InteriorSolucionComponent implements OnInit {
  showProgressBar: boolean = false;
  interiorData:CategorySolutions;
  configurationData: Configuration;
  constructor(
    private categorySolutionsService: CategorySolutionsService,
    private route: ActivatedRoute,
    private router: Router,
    private config: AppConfig
  ) { }

  ngOnInit(): void {
    this.configurationData = this.config.getConfig();
    this.route.params.subscribe( params => {
      if ( typeof params.solution !== 'undefined') {
        this.categorySolutionsService.findBySlug(params.solution).subscribe((category: CategorySolutions) => {
          if (typeof category[0] === 'undefined') this.router.navigate(['404']);
          console.log(category[0]);
          
          this.interiorData = category[0];
        })
      }
    });
  }

}
