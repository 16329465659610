<div #stickyMenu [class.sticky-top]="sticky">
  <div class="container" id="menu-component">
    <mat-toolbar class="navbar navbar-expand-sm mb-0 flex-lg-row flex-row-reverse">
      <button mat-icon-button (click)="onToggleSidenav()" class="navbar-toggler">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="logo">
        <a routerLink="/home">
          <img src="assets/logo.svg" />
        </a>
      </div>
      <div class="collapse navbar-collapse mat-typography">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link mr-lg-2" routerLink="/home" routerLinkActive="active">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mr-lg-2" routerLink="/product-services" routerLinkActive="active">Productos y
              soluciones</a>
          </li>
          <li class="nav-item mr-lg-2">
            <a class="nav-link" routerLink="/industries" routerLinkActive="active">Industrias</a>
          </li>
          <li class="nav-item mr-lg-2">
            <a class="nav-link" routerLink="/partners" routerLinkActive="active">Partners</a>
          </li>
          <li class="nav-item mr-lg-2">
            <a class="nav-link" routerLink="/ocean" routerLinkActive="active">Ocean</a>
          </li>
          <li class="nav-item mr-lg-2">
            <a class="nav-link" routerLink="/careers" routerLinkActive="active">Carreras</a>
          </li>
          <li class="nav-item mr-lg-2">
            <a class="nav-link" href="https://medium.com/entel-ocean" target="_blank">Blog</a>
          </li>
          <li class="nav-item mr-lg-2">
            <button class="nav-link font-weight-bold" [ngx-scroll-to]="'contact'">Contacto</button>
          </li>
        </ul>
      </div>
    </mat-toolbar>
  </div>
  <div class="menu__wrap" [ngClass]="{open: open}">
    <mat-sidenav opened #sidenav [@.disabled]="true" class="d-flex mat-typography" [ngClass]="{open: open}">
      <mat-nav-list class="py-3">
        <ul class="navbar-nav mobile pt-5">
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/home" outerLinkActive="active">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/product-services"
              outerLinkActive="active">Productos y soluciones</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/industries"
              routerLinkActive="active">Industrias</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/partners"
              routerLinkActive="active">Partners</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/ocean" routerLinkActive="active">ocean</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/careers" routerLinkActive="active">Carreras</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://medium.com/entel-ocean" target="_blank">Blog</a>
          </li>
          <li class="nav-item contacto">
            <button class="nav-link font-weight-bold" [ngx-scroll-to]="'contact'">Contacto</button>
          </li>
        </ul>
        <div class="logo-ocean rounded-circle">
          <img src="/assets/icons/ocean.svg" />
        </div>
        <button (click)="onToggleSidenav()" class="close__menu">
          <span class="material-icons">
            highlight_off
          </span>
        </button>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav opened #sidenav [@.disabled]="true" class="d-flex mat-typography" [ngClass]="{open: open}">
      <mat-nav-list class="py-3">
        <ul class="navbar-nav mobile pt-5">
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/home" outerLinkActive="active">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/product-services"
              outerLinkActive="active">Productos y soluciones</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/industries"
              routerLinkActive="active">Industrias</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/partners"
              routerLinkActive="active">Partners</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/ocean" routerLinkActive="active">Ocean</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onToggleSidenav()" routerLink="/careers" routerLinkActive="active">Carreras</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://medium.com/entel-ocean" target="_blank">Blog</a>
          </li>
          <li class="nav-item contacto">
            <button class="nav-link font-weight-bold" [ngx-scroll-to]="'contact'">Contacto</button>
          </li>
        </ul>
        <div class="logo-ocean rounded-circle">
          <img src="/assets/icons/ocean.svg" />
        </div>
        <button (click)="onToggleSidenav()" class="close__menu">
          <span class="material-icons">
            highlight_off
          </span>
        </button>
      </mat-nav-list>
    </mat-sidenav>
  </div>
</div>