import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Media, options } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { Document } from '@contentful/rich-text-types';

export class Industries {
    constructor(
        public title: string,
        public shortDescription: string,
        public longDescription: string,
        public image: Media,
        public imagenInterior: Media,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class IndustriesAdapter implements Adapter<Industries> {
  adapt(item: any): Industries {
    if (!item) return null;
    return new Industries(
      typeof item.title !== 'undefined' ? item.title : "",
      item.shortDescription,
      documentToHtmlString(item.longDescription as Document, options),
      item.image.fields,
      typeof item.imagenInterior !== 'undefined' ? item.imagenInterior.fields : null
    );
  }
}