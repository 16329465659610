import { Services } from './../../models/services.model';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServicesComponent implements OnInit {
  @Input() serviceTitle: string = "";
  @Input() full: boolean = false;
  @Input() services:Services[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
