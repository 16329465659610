import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { CategorySolutions } from './../../models/category-solutions.model';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-category-solutions',
  templateUrl: './category-solutions.component.html',
  styleUrls: ['./category-solutions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategorySolutionsComponent implements OnInit {
  @Input() categorySolutions:CategorySolutions[];
  @Input() message:string;
  @Input() full:boolean = false;
  @Input() currentSolution: CategorySolutions = null;
  @Output() cancel = new EventEmitter<boolean>();
  isDesktopDevice:boolean = true;
  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  hideForm() {
    this.cancel.emit(false)
  }

  showData(item: CategorySolutions) {
    if (this.full){
      this.currentSolution = item;
    } else {
      this.router.navigate(['product-services', item.slug]);
    }
  }

}
