import { Owners, OwnersAdapter } from './owners.model';
import { Careers, CareersAdapter } from './careers.model';
import { Media } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class BusinessArea {
    constructor(
        public title: string,
        public teaserImage: Media,
        public enlace: string,
        public owners: Owners[]= []
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class BusinessAreaAdapter implements Adapter<BusinessArea> {

  adapt(item: any): BusinessArea {
    let ownersAdapter = new OwnersAdapter();
    return new BusinessArea(
      item.title,
      item.teaserImage.fields,
      item.link,
      typeof item.owners !== 'undefined' ? item.owners.map((owner: any) => {
        if (typeof owner.fields === 'undefined') return null;
        return ownersAdapter.adapt(owner.fields)
      }).filter((item: Owners) => item) : []
    );
  }
}