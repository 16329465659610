<section class="join mat-typography" *ngIf="businessAreas || ownersData">
  <div class="join__wrapper container py-lg-5 d-flex flex-column mt-lg-5 pt-lg-0 pt-4">
    <div class="title__wrapper title__wrapper-black" *ngIf="tituloRepresentantes">
      <h2 class="title__wrapper-title">{{tituloRepresentantes}}</h2>
    </div>
    <div class="title__wrapper title__wrapper-black mb-lg-5" *ngIf="subtituloRepresentantes">
      <h3 class="title__wrapper-title mt-lg-3">{{subtituloRepresentantes}}</h3>
    </div>
    <div class="selector__executive my-5" *ngIf="selector && businessAreas">
      <mat-form-field>
        <mat-label>Selecciona tu area de interés</mat-label>
        <mat-select (selectionChange)="selectBusiness($event.value)" [(value)]="currentBusinessArea">
          <mat-option *ngFor="let businessArea of businessAreas" [value]="businessArea" class="select__item">
            {{businessArea.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div [ngClass]="{opened : owner === currentOwner}" class="col-lg-4 col-12 mb-lg-0 mb-5 pb-lg-0 pb-2"
        *ngFor="let owner of selector ? currentBusinessArea.owners :ownersData ">
        <div class="executive row p-0">
          <div class="col-12 my-auto text-center executive__inner">
            <img *ngIf="owner.profilePhoto" src={{owner.profilePhoto.file.url}}
              class="img-fluid rounded-circle mb-lg-3" />
              <div class="executive__data">
                <h3 class="m-lg-0">{{owner.fullName}}</h3>
                <p class="m-lg-0">{{owner.position}}</p>
                <button mat-raised-button color="secondary" class="text-uppercase mt-lg-3" (click)="agendarReunion(owner)">Agendar reunión</button>
              </div>
          </div><!-- /col-lg-12 col-8 -->
        </div><!-- /row -->
        <div class="triangle"></div>
        <div class="contact__container">
          <div class="triangle"></div>
          <ng-container *ngIf="!isDesktopDevice && owner === currentOwner">
            <app-schelude-contact-form (cancel)="closeAgendarReunion($event);" [show]="showContact" [owner]="currentOwner">
            </app-schelude-contact-form>
          </ng-container>
        </div>
      </div><!-- /.col-lg-3 -->
    </div><!-- row -->
    <ng-container *ngIf="isDesktopDevice">
      <app-schelude-contact-form (cancel)="closeAgendarReunion($event);" [show]="showContact" [owner]="currentOwner">
      </app-schelude-contact-form>
    </ng-container>
  </div>
</section>