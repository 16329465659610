import { AppConfig } from './../../config/app.config';
import { ShareDataService } from './../../services/share-data.service';
import { Configuration } from './../../models/configuration.model';
import { SocialMedia } from './../../models/social-media.model';
import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showBtn: boolean = false;
  configurationData: Configuration;
  constructor(
    private config: AppConfig
  ) { }

  ngOnInit() {
    this.configurationData = this.config.getConfig();
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    windowScroll > 2 ? this.showBtn = true : this.showBtn = false;
  }

}
