import { AppConfig } from './../../config/app.config';
import { Configuration } from './../../models/configuration.model';
import { Media, MediaAdapter } from './../../models/common.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageHomeService } from 'src/app/services/page-home.service';
import { PageHome } from 'src/app/models/page-home.model';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const pageId: string = '1f68B0PVueGJuyMVSlqErE';
const options = {
  renderNode: {
    'embedded-asset-block': (node: any) => {
      let mediaAdapter: MediaAdapter = new MediaAdapter();
      let img: Media = mediaAdapter.adapt(node.data.target.fields);
      const imgTag = `<img class="img-fluid" src="${img.file.url}" alt="${img.title}"/>`;
      return imgTag;
    }
  }
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  showProgressBar = true;
  homeData: PageHome;
  configurationData: Configuration;
  constructor(
    private pageHomeService: PageHomeService,
    private config: AppConfig

  ) { }

  ngOnInit() {
    this.configurationData = this.config.getConfig();
    this.pageHomeService.getPage(pageId).subscribe((home: PageHome) => {
      setTimeout(() => {
        this.showProgressBar = false;
      }, 1000);
      this.homeData = home;
      this.homeData.industriesNotVisibleSectionText = documentToHtmlString(this.homeData.industriesNotVisibleSectionText, options);
    })
    const targetParallax = document.getElementsByClassName('industria__contactanos');
    window.addEventListener("scroll", (e) => {
      if (typeof targetParallax !== 'undefined') {
        const yPos = -(window.scrollY / 8);
        var coords = '50% ' + yPos + 'px';
        targetParallax[0].setAttribute("style", "background-position: " + coords + ";");
      }
    });
  }
}