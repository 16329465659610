
import { ContentService } from './content.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageProductServices, PageProductServicesAdapter } from '../models/page-product-services.model';

@Injectable({ providedIn: 'root' })
export class PageProductServiceService {
  constructor(private contentService: ContentService, private adapter: PageProductServicesAdapter) { }
  getPage(pageId?:string): Observable<PageProductServices> {
    return this.contentService.getEntry(pageId).pipe(
      map(data => this.adapter.adapt(data.fields)));
  }
}