<div id="content">
    <!-- / Progress Bar -->
    <ng-container>
      <mat-progress-bar *ngIf="showProgressBar" color="primary" mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <!-- / Progress Bar -->
    <ng-container>
      <ng-container *ngIf="careersData">
        <app-highlights [home]="false" [highlights]="careersData.highlightsHeadRef"></app-highlights>
      </ng-container>
    </ng-container>
    <div class="bg-gris pt-lg-1">
      <section class="careers mat-typography" *ngIf="careersData">
          <div class="careers__wrapper container py-lg-5 mt-lg-5 d-flex flex-column">
              <div class="description" [innerHTML]="careersData.description"></div>
              <div class="title__wrapper title__wrapper-black text-center blue">
                <h3 class="title__wrapper-title mt-lg-3">{{careersData.textoPosiciones}}</h3>
              </div>
              <div class="text-center linkedin">
                <button mat-raised-button color="primary" class="mt-4 text-uppercase py-1">Contáctanos</button>
              </div>
        </div>  
      </section>
      <section class="careers mat-typography pb-lg-5" *ngIf="careersData">
          <div class="container careers__container">
              <div class="title__wrapper title__wrapper-black blue">
                <div class="description w-75" [innerHTML]="careersData.textoAReaNegocios"></div>
              </div>
                <ul class="p-lg-0 pb-lg-3 m-lg-0 row">
                    <li class="d-flex col-lg-3 align-content-center justify-content-center py-lg-5 flex-column" *ngFor="let items of careersData.areasDeNegocio">
                      <a href="{{items.link ? items.link : ''}}" class="d-block">
                          <img src={{items.teaserImage.file.url}} class="img-fluid" *ngIf="items.teaserImage" />
                          <h5 class="mt-lg-2">{{items.title}}</h5>
                      </a>
                    </li>
                </ul>
          </div>
      </section>
    </div>
    <section class="title__wrapper title__wrapper-blue pb-5 transformamos__title" *ngIf="careersData">
      <div class="p-lg-5 text-center mat-typography container w-80">
        <div class="title__wrapper-title" [innerHTML]="careersData.textoContacto"></div>
      </div>
    </section>
    <ng-container>
      <div class="container">
        <app-contact [negativo]="true"></app-contact>
      </div>
    </ng-container>
  </div>