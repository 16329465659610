import { Media } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class Blog {
    constructor(
        public titleArticle: string,
        public urlMedium: string,
        public shortDescription: string,
        public teaserImage: Media
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class BlogAdapter implements Adapter<Blog> {

  adapt(item: any): Blog {
    return new Blog(
      item.titleArticle,
      item.urlMedium,
      item.shortDescription,
      item.teaserImage.fields,
    );
  }
}