import { BusinessArea, BusinessAreaAdapter } from './business-area.model';
import { Highlights, HighlightsAdapter } from './highlights.model';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document } from '@contentful/rich-text-types';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { options } from './common.model';

export class PageCareers {
    constructor(
        public highlightsHeadRef: Highlights[] = [],
        public description: string,
        public linkLinkedin: string,
        public textoPosiciones: string,
        public textoAReaNegocios: string,
        public areasDeNegocio: BusinessArea[],
        public textoContacto: string,
        public bajadaContacto: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PageCareersAdapter implements Adapter<PageCareers> {

  adapt(item: any): PageCareers {
    let highlightsAdapter = new HighlightsAdapter();
    let businessAdapter = new BusinessAreaAdapter();
    
    return new PageCareers(
      typeof item.highlightsHeadRef !== 'undefined' ? item.highlightsHeadRef.map((highlight: any) => {
        if (!highlight.fields) return null;
        return highlightsAdapter.adapt(highlight.fields)
      }) : [],
      documentToHtmlString(item.descripcion as Document, options),
      item.linkLinkedin,
      item.textoPosiciones,
      documentToHtmlString(item.textoAReaNegocios as Document, options),
      typeof item.areasDeNegocio !== 'undefined' ? item.areasDeNegocio.map((area: any) => {
        if (!area.fields) return null;
        return businessAdapter.adapt(area.fields);
      }): "",
      documentToHtmlString(item.textoContacto as Document, options),
      documentToHtmlString(item.bajadaContacto as Document, options),
    );
  }
}