<div id="content">
    <!-- / Progress Bar -->
    <ng-container>
      <mat-progress-bar *ngIf="showProgressBar" color="primary" mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <!-- / Progress Bar -->
    <ng-container>
      <ng-container *ngIf="interiorData">
        <app-highlights [highlights]="interiorData.highlightsHead" [home]="false"></app-highlights>
      </ng-container>
    </ng-container>
    <section class="breadcrumb p-0 m-0" *ngIf="interiorData">
        <div class="breadcrumb__container container d-flex align-items-center mat-typography py-3">
            <a routerLink="/product-services" class="d-flex mr-2">
                <span class="material-icons">
                chevron_left
                </span>
            </a>
            <p class="d-flex m-0">inicio / productos y servicios / <strong> {{interiorData.category}}</strong></p>
        </div>
    </section>
    <section class="content p-lg-5" *ngIf="interiorData">
        <div class="content__container container">
            <div class="row d-flex flex-lg-row flex-column-reverse">
                <div class="col-lg-8">
                    <div class="" [innerHTML]="interiorData.longDescription"></div>
                </div>
                <div class="col-lg-4 d-flex align-items-center mx-lg-auto">
                    <img class="img-fluid align-self-center d-flex mx-lg-auto" src="{{interiorData.solution[0].teaserImage.file.url}}" *ngIf="interiorData.solution[0]" />
                </div>
            </div>
        </div>
    </section>
    <section class="services pb-lg-5 mat-typography pt-lg-0 pt-2 pb-4" *ngIf="interiorData">
        <section class="title__wrapper title__wrapper-black pb-lg-5 pb-3 mt-lg-5 mt-4"
          *ngIf="interiorData">
          <div class="pt-lg-5 mat-typography container">
            <h2 class="title__wrapper-title text-left blue" [innerHTML]="interiorData.titleServices"></h2>
          </div>
        </section>
        <div class="services__wrapper container">
            <app-services [services]="interiorData.services" [full]="true"></app-services>
        </div>
    </section>
    <section class="title__wrapper title__wrapper-black pb-5 mt-lg-5 mt-4" *ngIf="interiorData">
      <div class="pt-lg-5 mat-typography container">
        <h2 class="title__wrapper-title text-left" [innerHTML]="interiorData.titleIndustries"></h2>
      </div>
      <ng-container *ngIf="interiorData">
        <app-industries [industries]="interiorData.industries" [inline]="true" [message]="'Conoce todas las industrias con las que trabajamos'"></app-industries>
      </ng-container><!-- insdustries -->
    </section>
    <section class="title__wrapper title__wrapper-black pb-lg-5 pb-2 mt-lg-0 mt-4" *ngIf="interiorData">
      <div class="pt-lg-5 mat-typography container">
        <h2 class="title__wrapper-title text-left" [innerHTML]="interiorData.titleSuccessful"></h2>
      </div>
    </section>
    <ng-container *ngIf="interiorData && configurationData">
      <app-success [success]="interiorData.successStories" [background]="configurationData.backgroundSuccess"></app-success>
    </ng-container>
    <section class="bg-gris pt-lg-2 mt-lg-0 mt-4 pb-lg-5" *ngIf="interiorData">
      <ng-container *ngIf="interiorData">
        <app-owners [ownersData]="interiorData.owners"
          [subtituloRepresentantes]="'Agenda una reunión con uno de nuestros representantes'"
          [tituloRepresentantes]="'Representantes'">
        </app-owners>
      </ng-container>
    </section>
    <section class="title__wrapper title__wrapper-blue pb-5 transformamos__title mt-lg-0" *ngIf="interiorData">
      <div class="p-lg-5 text-center mat-typography container">
        <div class="title__wrapper-title text-center mx-auto" [innerHTML]="interiorData.textoContacto"></div>
      </div>
    </section>
    <ng-container>
      <div class="container">
        <app-contact [negativo]="true"></app-contact>
      </div>
    </ng-container>
    <div class="nosotros container mt-5 mb-lg-0 mb-5 pt-lg-5" *ngIf="interiorData">
      <div class="title__wrapper title__wrapper-red">
        <div class="mb-lg-5 title__wrapper-title mx-auto text-center" [innerHTML]="interiorData.bajadaContacto"></div>
      </div>
    </div>
</div>