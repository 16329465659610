import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Media, options } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class Services {
    constructor(
        public title: string,
        public longDescription: string,
        public teaserImage: Media,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class ServicesAdapter implements Adapter<Services> {
  adapt(item: any): Services {
    return new Services(
      item.title,
      item.longDescription !== 'undefined' ? documentToHtmlString(item.longDescription, options) : "",
      item.teaserImage.fields
    );
  }
}