import { InteriorSolucionComponent } from './../pages/interior-solucion/interior-solucion.component';
import { PageIndustriesComponent } from './../pages/page-industries/page-industries.component';
import { PageProductosServiciosComponent } from './../pages/page-productos-servicios/page-productos-servicios.component';
import { PageCareersComponent } from './../pages/page-careers/page-careers.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { AboutUsComponent } from '../pages/about-us/about-us.component';
import { PagePartnersComponent } from '../pages/page-partners/page-partners.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'ocean',
    component: AboutUsComponent
  },
  {
    path: 'partners',
    component: PagePartnersComponent
  },
  {
    path: 'careers',
    component: PageCareersComponent
  },
  {
    path: 'industries',
    component: PageIndustriesComponent
  },
  {
    path: 'product-services',
    component: PageProductosServiciosComponent,
  },
  {
    path: 'product-services/:solution',
    component: InteriorSolucionComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}