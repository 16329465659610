import { PageCareersService } from './../../services/page-careers.service';
import { PageCareers } from './../../models/page-careers.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
const pageId = 'eMvrujPJMhou3XmWnBBqU';
@Component({
  selector: 'app-page-careers',
  templateUrl: './page-careers.component.html',
  styleUrls: ['./page-careers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageCareersComponent implements OnInit {
  showProgressBar = false;
  careersData: PageCareers;
  constructor(
    private pageCareersService: PageCareersService
  ) { }

  ngOnInit(): void {
    this.pageCareersService.getPage(pageId).subscribe((careers: PageCareers) => {
      this.careersData = careers;
    })
  }

}

