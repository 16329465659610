<div class="currentIndustry container mb-lg-0 mb-5">
    <mat-card class="currentIndustry__wrapper container col-lg-12 p-lg-5 flex-column" *ngIf="currentIndustry">
      <mat-card-header class="mb-lg-5">
        <mat-card-title>
          {{currentIndustry.title}}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-lg-12 industry__content">
            <img class="mb-lg-4 mb-2 img-fluid float-right" *ngIf="currentIndustry.imagenInterior" src={{currentIndustry.imagenInterior.file.url}}
          alt="Generic placeholder image">
            <div class="pt-lg-0 pt-0" [innerHTML]="currentIndustry.longDescription"></div>
          </div>
          <div class="mt-4 mt-lg-2 text-right more" *ngIf="message">  <!-- more -->
            <h5 class="display-5 justify-content-end mb-lg-0">
              <a href="#" class="d-flex">
                <!-- Conoce todos nuestros productos y soluciones -->
                {{message}}
                <span class="material-icons ml-2">
                  keyboard_arrow_right
                </span>
              </a>
            </h5>
          </div><!-- more -->
        </div>
      </mat-card-content>
    </mat-card>
  </div><!-- /solutions -->