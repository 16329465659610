<div id="content">
    <!-- / Progress Bar -->
    <ng-container>
      <mat-progress-bar *ngIf="showProgressBar" color="primary" mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <!-- / Progress Bar -->
    <ng-container>
      <ng-container *ngIf="productServiceData">
        <app-highlights [highlights]="productServiceData.highlightsHead" [home]="false"></app-highlights>
      </ng-container>
    </ng-container>
    <section class="products pb-lg-0 pb-0" *ngIf="productServiceData">
      <div class="background"></div>
      <div class="products__container mat-typography px-lg-5 pt-lg-5 pb-lg-4 pb-3 container">
        <app-category-solutions [categorySolutions]="productServiceData.categorySolutions" [currentSolution]="currentSolution" [full]="true" [message]="'Cónoce todo sobre nuestros productos'"></app-category-solutions>
      </div><!-- products__container -->
    </section><!-- products -->
    <section class="title__wrapper title__wrapper-cyan pb-5 transformamos__title mt-lg-5 mt-4" *ngIf="productServiceData">
      <div class="p-lg-5 text-center mat-typography container">
        <div class="title__wrapper-title text-center mx-auto" [innerHTML]="productServiceData.textoContacto"></div>
      </div>
    </section>
    <ng-container>
      <div class="container">
        <app-contact [negativo]="true"></app-contact>
      </div>
    </ng-container>
    <div class="nosotros container mt-5 mb-lg-0 mb-5 pt-lg-5" *ngIf="productServiceData">
      <div class="title__wrapper title__wrapper-red">
        <div class="mb-lg-5 title__wrapper-title mx-auto text-center" [innerHTML]="productServiceData.bajadaContacto"></div>
      </div>
    </div>
</div>