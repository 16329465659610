import { Highlights, HighlightsAdapter } from './highlights.model';
import { MediaAppearancesAdapter, MediaAppearances } from './media-appearances.model';
import { SocialMedia, SocialMediaAdapter } from './social-media.model';
import { Blog, BlogAdapter } from './blog.model';
import { SuccessStories, SuccessStoriesAdapter } from './success-stories.model';
import { CategorySolutions, CategorySolutionsAdapter } from './category-solutions.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { Media, MediaAdapter } from './common.model';
import { Industries, IndustriesAdapter } from './industries.model';
import { ClientExperienceAdapter, ClientExperience } from './client-experience.model';

export class PageHome {
    constructor(
        public highlightsHeadRef: Highlights[],
        public headerTitleImage: string,
        public headerSectionShortDescriptionImage: string,
        public categoryServicesTitle: string,
        // public categoryServicesImage: Media,
        public categorySolutions: CategorySolutions[],
        public industriesTitle: string,
        public industries: Industries[],
        public industriesNotVisibleSectionText: any,
        // public industriesNotVisibleImage: Media,
        // public successStoriesBackgroundImage: Media,
        public successStories: SuccessStories[],
        public mediaAppearances: MediaAppearances,
        public blogTitleSection: string,
        public blogs: Blog[],
        public clientExperience: ClientExperience,
        public successHistoriesLabel: string,
        // public footerImageBackg: Media,
        // public socialMedia: SocialMedia,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PageHomeAdapter implements Adapter<PageHome> {

  adapt(item: any): PageHome {
    let industriesAdapter = new IndustriesAdapter();
    let successStoriesAdapter = new SuccessStoriesAdapter();
    let blogAdapter = new BlogAdapter();
    let mediaAppearancesAdapter = new MediaAppearancesAdapter();
    let highlightsAdapter = new HighlightsAdapter();
    let categorySolutionsAdapter = new CategorySolutionsAdapter();
    let clienteExperienceAdapter = new ClientExperienceAdapter();
    return new PageHome(
      typeof item.highlightsHeadRef !== 'undefined' ? item.highlightsHeadRef.map((highlight: any) => {
        if (!highlight.fields) return null;
        return highlightsAdapter.adapt(highlight.fields)
      }) : [],
      item.headerTitleImage,
      item.headerSectionShortDescriptionImage,
      item.categoryServicesTitle,
      // item.categoryServicesImage.fields,
      item.categorySolutions.map((category: any) => {
        if (!category.fields) return false; 
        return categorySolutionsAdapter.adapt(category.fields)
      }),
      item.industriesTitle,
      item.industries.map((industries: any) => industriesAdapter.adapt(industries.fields)),
      item.industriesNotVisibleSectionText,
      // item.industriesNotVisibleImage.fields,
      // item.successStoriesBackgroundImage.fields,
      item.successStories.map((stories: any) => successStoriesAdapter.adapt(stories.fields)),
      item.mediaAppearances.map((mediaAppearances: any) => mediaAppearancesAdapter.adapt(mediaAppearances.fields)).shift(),
      item.blogTitleSection,
      item.blogs.map((blog: any) => blogAdapter.adapt(blog.fields)),
      item.clientExperience.map((clienteExperience: any) => clienteExperienceAdapter.adapt(clienteExperience.fields)),
      item.successHistoriesLabel
      // item.footerImageBackg.fields,
      // item.socialMedia.map((socialMedia: any) => socialMediaAdapter.adapt(socialMedia.fields)),
    );
  }
}