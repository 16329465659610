import { Owners, OwnersAdapter } from './owners.model';
import { SuccessStoriesAdapter } from './success-stories.model';
import { SuccessStories } from 'src/app/models/success-stories.model';
import { IndustriesAdapter } from './industries.model';
import { Industries } from 'src/app/models/industries.model';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Highlights, HighlightsAdapter } from './highlights.model';
import { map } from 'rxjs/operators';
import { ContentService } from './../services/content.service';
import { Solution, SolutionAdapter } from './solution.model';
import { ServicesAdapter, Services } from './services.model';
import { Media, File, options } from './common.model';
import { Document } from '@contentful/rich-text-types';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { Observable } from 'rxjs';

export class CategorySolutions {
    constructor(
        public highlightsHead: Highlights[],
        public category: string,
        public shortDescription: string,
        public icon: Media,
        public slug: string,
        public longDescription: string,
        public solution: Solution[],
        public services: Services[],
        public bajadaContacto: string,
        public textoContacto: string,
        public industries: Industries[],
        public titleServices: string,
        public titleIndustries: string,
        public titleOwners: string,
        public titleSuccessful: string,
        public successStories: SuccessStories[],
        public owners: Owners[]
        // public teaserImage: Media,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class CategorySolutionsAdapter implements Adapter<CategorySolutions> {

  adapt(item: any): CategorySolutions {
    let solutionsAdapter = new SolutionAdapter();
    let servicesAdapter = new ServicesAdapter();
    let newService: Services[] = [];
    let newSolution: Solution[] = [];
    let highlightsAdapter = new HighlightsAdapter();
    let industriesAdapter = new IndustriesAdapter();
    let successStoriesAdapter = new SuccessStoriesAdapter();
    let ownersAdapter = new OwnersAdapter();

    typeof item.solutions !== 'undefined' ? item.solutions.map((solution: any) => {
      if (typeof item !== 'undefined' && !item.fields) {
        return this.getItem(solution.sys.id, solutionsAdapter).subscribe((solution: Solution) => {
          newSolution.push(solution);
        });
      }
    }) : [];
    
    item.categoryServices.map((service: any) => {
      if (typeof item !== 'undefined' && !item.fields) {
        return this.getItem(service.sys.id, servicesAdapter).subscribe((service: Services) => {
          newService.push(service);
        });
      }
    });
    
    return new CategorySolutions(
      typeof item.highlightsHead !== 'undefined' ? item.highlightsHead.map((highlight: any) => {
        if (!highlight.fields) return null;
        return highlightsAdapter.adapt(highlight.fields)
      }) : [],
      item.category,
      item.shortDescription !== 'undefined' ? documentToHtmlString(item.shortDescription as Document, options) : "",
      item.icon.fields,
      item.slug ? item.slug : "",
      item.longDescription !== 'undefined' ? documentToHtmlString(item.longDescription as Document, options) : "",
      newSolution,
      newService,
      item.bajadaContacto !== 'undefined' ? documentToHtmlString(item.bajadaContacto, options) : "",
      item.textoContacto !== 'undefined' ? documentToHtmlString(item.textoContacto, options) : "",
      typeof item.industries !== 'undefined' ? item.industries.map((industries: any) => industriesAdapter.adapt(industries.fields)) : [],
      item.titleServices,
      item.titleIndustries,
      item.titleOwners,
      item.titleSuccessful,
      typeof item.successStories !== 'undefined' ? item.successStories.map((success: any) => {
        if (!success.fields) return null;
        return successStoriesAdapter.adapt(success.fields)
      }) : [],
      typeof item.owners !== 'undefined' ? item.owners.map((owner: any) => {
        if (!owner.fields) return null;
        return ownersAdapter.adapt(owner.fields)
      }) : [],
    );
  }

  getItem(id: string, adapter: any): Observable<any> {
    let contentService = new ContentService();
    return contentService.getEntry(id).pipe(
      map(data => adapter.adapt(data.fields))
    );
  }
}