import { Owners } from './../../models/owners.model';
import { NgForm } from '@angular/forms';
import { Configuration } from 'src/app/models/configuration.model';
import { AppConfig } from './../../config/app.config';
import { EmailService } from './../../services/email.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-schelude-contact-form',
  templateUrl: './schelude-contact-form.component.html',
  styleUrls: ['./schelude-contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheludeContactFormComponent implements OnInit {
  @Input() show:boolean = false;
  @Input() owner:Owners;
  @ViewChild('f') signupForm: NgForm;
  @Output() cancel = new EventEmitter<boolean>();
  submitted = false;
  contact = {
    nameLastName : '',
    email : '',
    message :'',
    telefono :'',
  }

  configurationData:Configuration;

  constructor(
    private emailService: EmailService,
    private config: AppConfig
  ) { }

  ngOnInit(): void {
    this.configurationData = this.config.getConfig();
  }

  onSubmit(owner: Owners){
    this.contact.nameLastName = this.signupForm.value.nameLastName;
    this.contact.email = this.signupForm.value.email;
    this.contact.message = this.signupForm.value.message;
    this.contact.telefono = this.signupForm.value.telefono;
    let usuario = `<tr><td style=\"padding: 5px; border: 1px solid #009CDE;\">Nombre</td><td style=\"padding: 5px; border: 1px solid #009CDE;\">${this.contact.nameLastName}</td></tr>`;
    let email = `<tr><td style=\"padding: 5px; border: 1px solid #009CDE;\">Email</td><td style=\"padding: 5px; border: 1px solid #009CDE;\">${this.contact.email}</td></tr>`;
    let message = `<tr><td style=\"padding: 5px; border: 1px solid #009CDE;\">Mensaje</td><td style=\"padding: 5px; border: 1px solid #009CDE;\">${this.contact.message}</td></tr>`;
    let telefono = `<tr><td style=\"padding: 5px; border: 1px solid #009CDE;\">Teléfono</td><td style=\"padding: 5px; border: 1px solid #009CDE;\">${this.contact.telefono}</td></tr>`;
    let content = `${usuario}${email}${message}${telefono}`;
    let data = {
      "templateName": "contact_form",
      "sendTo": this.owner.email,
      "templateData": {
        "LOGO": environment.emailConfig.logo,
        "ASUNTO": `${this.contact.nameLastName} se contactó, a continuación adjuntamos los datos`,
        "NOMBRE": this.contact.nameLastName,
        "CONTENT": content
      }
    }

    let dataUser = {
      "templateName": "contact_form",
      "sendTo": this.contact.email,
      "templateData": {
        "LOGO": environment.emailConfig.logo,
        "ASUNTO": `${this.contact.nameLastName}. <br />Gracias por contactarnos, acá adjuntamos los datos que ajuntaste`,
        "NOMBRE": this.contact.nameLastName,
        "CONTENT": content
      }
    }
    this.emailService.sendMessage(data).subscribe((resp) => {
      this.emailService.sendMessage(dataUser).subscribe((resp) => {
        console.log(resp);
        this.submitted = true;
      })
      
    })
    this.signupForm.reset();
  }

  hideForm() {
    this.cancel.emit(false)
  }

}
