import { SocialMedia, SocialMediaAdapter } from './social-media.model';
import { Media } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class Configuration {
    constructor(
        public backgroundSuccess: Media,
        public backgroundContact: Media,
        public backgroundFooter: Media,
        public network: SocialMedia[],
        public politicLink: string,
        public copyright: string,
        public correoContacto: string,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class ConfigurationAdapter implements Adapter<Configuration> {
  
  adapt(item: any): Configuration {
    let networkAdapter = new SocialMediaAdapter();
    return new Configuration(
      item.backgroundSuccess.fields,
      item.backgroundContact.fields,
      item.backgroundFooter.fields,
      typeof item.network !== 'undefined' ? item.network.map((rrss: any) => {
        if (!rrss.fields) return null;
        return networkAdapter.adapt(rrss.fields)
      }) : [],
      item.politicLink,
      item.copyright,
      item.correoDeContacto,
    );
  }
}