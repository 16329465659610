<div id="content">
    <!-- / Progress Bar -->
    <ng-container>
      <mat-progress-bar *ngIf="showProgressBar" color="primary" mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <!-- / Progress Bar -->
    <ng-container>
      <ng-container *ngIf="industriesData">
        <app-highlights [highlights]="industriesData.highlightsHead" [home]="false"></app-highlights>
      </ng-container>
    </ng-container>
    <div class="bg__industries pt-lg-0 pt-4">
      <section class="industries__title mt-5 title__wrapper" *ngIf="industriesData">
        <!-- title__wrapper -->
        <div class="background"></div>
        <div class="px-lg-5 pt-lg-5 pb-lg-0 mt-lg-0 mt-lg-4 text-center mat-typography container">
          <div class="title__wrapper title__wrapper-black">
            <div class="title__wrapper-title" [innerHTML]="industriesData.industriesTitle"></div>
          </div>
        </div>
      </section>
      <!-- title__wrapper -->
      <ng-container *ngIf="industriesData">
        <app-industries [industries]="industriesData.industries" [inline]="true" [full]="true" [link]="false" [currentIndustry]="currentIndustry"></app-industries>
      </ng-container><!-- insdustries -->
    </div>
    <section class="products pb-lg-0 pb-0" *ngIf="industriesData">
      <div class="products__container mat-typography px-lg-5 pt-lg-5 pb-lg-4 pb-3 container">
        <app-category-solutions [categorySolutions]="industriesData.categorySolutions"></app-category-solutions>
        <div class="mt-4 mt-lg-2 text-right more">
          <!-- more -->
          <h5 class="display-5 justify-content-end mb-lg-0">
            <a routerLink="/product-services" class="d-flex">
              Conoce todos nuestros productos y soluciones
              <span class="material-icons ml-2">
                keyboard_arrow_right
              </span>
            </a>
          </h5>
        </div>
        <!-- more -->
      </div><!-- products__container -->
    </section><!-- products -->
    <section class="bg-gris mt-lg-5 mt-4" *ngIf="industriesData">
      <app-owners 
        [ownersData]="industriesData.owners"
        [subtituloRepresentantes]="industriesData.subtituloRepresentantes"
        [tituloRepresentantes]="industriesData.tituloRepresentantes">
      </app-owners>
    </section>
    <section class="title__wrapper title__wrapper-blue pb-5 transformamos__title mt-lg-0" *ngIf="industriesData">
      <div class="p-lg-5 text-center mat-typography container">
        <div class="title__wrapper-title text-center mx-auto" [innerHTML]="industriesData.textoContacto"></div>
      </div>
    </section>
    <ng-container>
      <div class="container">
        <app-contact [negativo]="true"></app-contact>
      </div>
    </ng-container>
    <div class="nosotros container mt-5 mb-lg-0 mb-5 pt-lg-5" *ngIf="industriesData">
      <div class="title__wrapper title__wrapper-red">
        <div class="mb-lg-5 title__wrapper-title mx-auto text-center" [innerHTML]="industriesData.bajadaContacto"></div>
      </div>
    </div>
</div>