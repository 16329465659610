<section id="contact" [ngClass]="{'mt-n5' : negativo}" class="contact d-flex justify-content-center flex-column"><!--contact -->
  <form (ngSubmit)="onSubmit()" #f="ngForm" *ngIf="!submitted">
    <mat-card class="contact__wrapper container col-lg-6 p-lg-5 pb-5">
      <mat-card-header class="d-flex justify-content-center mb-3 mb-lg-5">
        <mat-card-title>Contáctanos</mat-card-title>
      </mat-card-header>
      <mat-card-content class="px-2 d-flex flex-column">
        <mat-form-field appearance="legacy" floatLabel="always">
          <mat-label>Nombre y apellido</mat-label>
          <input 
            matInput 
            placeholder="" 
            class="pt-1 mt-3"
            ngModel
            id="nameLastName"
            name="nameLastName"
            required
            #nameLastName="ngModel">
        </mat-form-field>
        <div class="help-block text-danger" style="font-size:10px" *ngIf="!nameLastName.valid && nameLastName.touched">Por favor introduzca Nombre y Apellido</div>
        <mat-form-field appearance="legacy" floatLabel="always">
          <mat-label>Email</mat-label>
          <input
            id="email"
            matInput 
            placeholder="" 
            class="pt-1 mt-3"
            ngModel 
            name="email"
            required
            email
            #email="ngModel">
        </mat-form-field>
        <div class="help-block text-danger" style="font-size:10px" *ngIf="!email.valid && email.touched">Por favor introduzca un e-mail válido</div>
        <mat-form-field appearance="legacy" floatLabel="always">
          <mat-label>Mensaje</mat-label>
          <textarea 
            matInput 
            #textArea 
            maxlength="140" 
            ngModel
            name="message"
            placeholder="" 
            class="pt-1 mt-3"
            required
            #message="ngModel">
          </textarea>
          <mat-hint align="end">(Max {{textArea.value?.length || 0}}/140 chars)</mat-hint>
        </mat-form-field>
        <div class="help-block text-danger" style="font-size:10px" *ngIf="!message.valid && message.touched">Por favor introduzca el mensaje</div>
      </mat-card-content>
      <mat-card-actions class="d-flex justify-content-center">
        <button 
          mat-raised-button 
          color="primary" 
          class="mt-4 text-uppercase py-1"
          type="submit"
          [disabled]="!f.valid">enviar
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
  <div class="row" *ngIf="submitted">
    <mat-card class="contact__wrapper container col-lg-5 p-5">
      <mat-card-header class="d-flex justify-content-center mb-5">
        <mat-card-title>Contáctanos: </mat-card-title>
      </mat-card-header>
      <mat-card-content class="px-2 d-flex flex-column">
        <span class="thanks d-flex flex-column align-items-center">
          <p>¡Gracias por su mensaje, a la brevedad será contactado!</p>
          <button mat-raised-button  color="primary" (click)="submitted = !submitted">Enviar otro mensaje</button>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
</section><!-- /contact -->