import { IndustriesAdapter } from './industries.model';
import { Industries } from 'src/app/models/industries.model';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Highlights, HighlightsAdapter } from './highlights.model';
import { Media, options } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class Solution {
    constructor(
        public highlightsHead: Highlights[],
        public title: string,
        public slug: string,
        public shortDescription: string,
        public longDescription: string,
        public industries: Industries[],
        public teaserImage: Media,
        public textoContacto: string,
        public serviciosTitle: string,
        public industriasTitle: string,
        public bajadaContacto: string,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class SolutionAdapter implements Adapter<Solution> {
  adapt(item: any): Solution {
    let industriesAdapter = new IndustriesAdapter();
    let highlightsAdapter = new HighlightsAdapter();
    return new Solution(
      typeof item.highlightsHead !== 'undefined' ? item.highlightsHead.map((highlight: any) => {
        if (!highlight.fields) return null;
        return highlightsAdapter.adapt(highlight.fields)
      }) : [],
      item.title,
      item.slug,
      item.shortDescription,
      item.longDescription !== 'undefined' ? documentToHtmlString(item.longDescription, options) : "",
      typeof item.industries !== 'undefined' ? item.industries.map((industry: any) => {
        if (!industry.fields) return null;
        return industriesAdapter.adapt(industry.fields)
      }) : [],
      item.teaserImage.fields,
      item.textoContacto !== 'undefined' ? documentToHtmlString(item.textoContacto, options) : "",
      item.serviciosTitle !== 'undefined' ? documentToHtmlString(item.serviciosTitle, options) : "",
      item.industriasTitle !== 'undefined' ? documentToHtmlString(item.industriasTitle, options) : "",
      item.bajadaContacto !== 'undefined' ? documentToHtmlString(item.bajadaContacto, options) : "",
    );
  }
}