<div class="row">
  <ng-container *ngFor="let items of categorySolutions">
    <div class="product__wrapper col-lg-4 col-12" *ngIf="items!= false">
      <a (click)="showData(items); []">
        <div class="product p-3 pt-4" [class.current]="currentSolution === items && full" [class.h-100]="isDesktopDevice">
          <div class="row">
            <div class="col-lg-12 col-4 product-title">
              <div class="icon">
                <span class="mb-3 icon-content"><img *ngIf="items.icon" src={{items.icon.file.url}} /></span>
                <h5 class="icon-title d-none d-md-inline font-weight-bold">{{items.category}}</h5>
              </div><!-- /icon -->
            </div><!-- /col-lg-12 col-4 -->
            <div class="col-lg-12 col-8 product-description">
              <h5 class="d-block d-sm-none">{{items.category}}</h5>
              <div class="pt-0" [innerHTML]="items.shortDescription"></div>
              <div class="button-container d-none d-md-inline float-right">
                <button mat-mini-fab color="secondary" aria-label="Example icon button with a delete icon">
                  <mat-icon>{{full ? 'add' : 'arrow_forward'}}</mat-icon>
                </button>
              </div><!-- /button-container d-none d-md-inline -->
            </div><!-- /col-lg-12 col-8 -->
          </div><!-- /row -->
        </div><!-- /product p-3 pt-4 -->
      </a>
      <div class="triangle" [class.d-block]="currentSolution === items" *ngIf="full"></div>
      <ng-container *ngIf="currentSolution === items && isDesktopDevice === false">
        <app-solution [currentSolution]="currentSolution" [message]="message"></app-solution>
      </ng-container>
    </div><!-- /.col-lg-4 -->
  </ng-container>
  <ng-container *ngIf="full && isDesktopDevice">
    <app-solution [currentSolution]="currentSolution" [message]="message"></app-solution>
  </ng-container>
  
</div>