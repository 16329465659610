import { Owners, OwnersAdapter } from './owners.model';
import { Industries, IndustriesAdapter } from './industries.model';
import { options } from './common.model';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
// import { CategorySolutions, CategorySolutionsAdapter } from './category-solutions.model';
import { Highlights, HighlightsAdapter } from './highlights.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { CategorySolutions, CategorySolutionsAdapter } from './category-solutions.model';

export class PageIndustries {
    constructor(
        public highlightsHead: Highlights[],
        public industries: Industries[],
        public owners: Owners[],
        public categorySolutions: CategorySolutions[],
        public industriesTitle: string,
        public tituloRepresentantes: string,
        public subtituloRepresentantes: string,
        public textoContacto: string,
        public bajadaContacto: string,
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PageIndustriesAdapter implements Adapter<PageIndustries> {

  adapt(item: any): PageIndustries {
    let highlightsAdapter = new HighlightsAdapter();
    let industriesAdapter = new IndustriesAdapter();
    let categorySolutionsAdapter = new CategorySolutionsAdapter();
    let ownersAdapter = new OwnersAdapter();

    return new PageIndustries(
      typeof item.highlightsHead !== 'undefined' ? item.highlightsHead.map((highlight: any) => {
        if (!highlight.fields) return null;
        return highlightsAdapter.adapt(highlight.fields)
      }) : [],
      typeof item.industries !== 'undefined' ? item.industries.map((industry: any) => {
        if (!industry.fields) return null;
        return industriesAdapter.adapt(industry.fields)
      }) : [],
      typeof item.owners !== 'undefined' ? item.owners.map((owner: any) => {
        if (!owner.fields) return null;
        return ownersAdapter.adapt(owner.fields)
      }) : [],
      typeof item.categorySolutions !== 'undefined' ? item.categorySolutions.map((category: any) => {
        if (!category.fields) return null;
        return categorySolutionsAdapter.adapt(category.fields)
      }) : [],
      item.industriesTitle !== 'undefined' ? documentToHtmlString(item.industriesTitle, options) : "",
      item.tituloRepresentantes,
      item.subtituloRepresentantes,
      item.textoContacto !== 'undefined' ? documentToHtmlString(item.textoContacto, options) : "",
      item.bajadaContacto !== 'undefined' ? documentToHtmlString(item.bajadaContacto, options) : "",
    );
  }
}