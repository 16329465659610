<div class="contact" *ngIf="show">
  <section class="contact d-flex justify-content-center flex-column">
    <!--contact -->
    <form (ngSubmit)="onSubmit(owner)" #f="ngForm" *ngIf="!submitted">
      <mat-card class="contact__wrapper container col-lg-12 px-lg-5 px-2">
        <mat-card-header class="d-flex justify-content-start m-0">
          <mat-card-title>Agendar reunión</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-2 d-flex flex-column">
          <div class="row">
            <div class="col-lg-6 flex-column d-flex">
              <mat-form-field appearance="legacy" floatLabel="always">
                <mat-label>Nombre y apellido</mat-label>
                <input matInput placeholder="" class="pt-1 mt-3" ngModel id="nameLastName" name="nameLastName" required
                  #nameLastName="ngModel">
              </mat-form-field>
              <div class="help-block text-danger" style="font-size:10px"
                *ngIf="!nameLastName.valid && nameLastName.touched">Por favor introduzca Nombre y Apellido</div>
              <mat-form-field appearance="legacy" floatLabel="always">
                <mat-label>Email</mat-label>
                <input id="email" matInput placeholder="" class="pt-1 mt-3" ngModel name="email" required email
                  #email="ngModel">
              </mat-form-field>
              <div class="help-block text-danger" style="font-size:10px" *ngIf="!email.valid && email.touched">Por favor
                introduzca un e-mail válido</div>
              <mat-form-field appearance="legacy" floatLabel="always">
                <mat-label>Teléfono</mat-label>
                <input id="telefono" matInput placeholder="" class="pt-1 mt-3" ngModel name="telefono" required
                  #telefono="ngModel">
              </mat-form-field>
              <div class="help-block text-danger" style="font-size:10px" *ngIf="!telefono.valid && telefono.touched">Por
                favor introduzca un teléfono válido</div>
            </div>
            <div class="col-lg-6 d-flex flex-column justify-content-end">
              <mat-form-field appearance="legacy" floatLabel="always" class="stretch-height">
                <mat-label>Mensaje</mat-label>
                <textarea matInput #textArea maxlength="250" ngModel name="message" placeholder="" class="pt-1 mt-3"
                  required #message="ngModel">
                </textarea>
                <mat-hint align="end">(Max {{textArea.value?.length || 0}}/250 chars)</mat-hint>
              </mat-form-field>
              <div class="help-block text-danger" style="font-size:10px" *ngIf="!message.valid && message.touched">Por
                favor introduzca el mensaje</div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-end pb-3">
          <button mat-raised-button color="secondary" class="mt-4 text-uppercase py-1 mr-lg-3" (click)="hideForm();"
            type="button">
            Cancelar
          </button>
          <button mat-raised-button color="secondary" class="mt-4 text-uppercase py-1" type="submit"
            [disabled]="!f.valid">Enviar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
    <div class="row" *ngIf="submitted">
      <mat-card class="contact__wrapper container col-lg-5 p-5">
        <mat-card-header class="d-flex justify-content-center mb-5">
          <mat-card-title>Contáctanos: </mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-2 d-flex flex-column">
          <span style="color:black;text-align: center;">¡Gracias por su mensaje, a la brevedad será contactado!</span>
        </mat-card-content>
      </mat-card>
    </div>
  </section><!-- /contact -->
</div>