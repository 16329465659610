import { Injectable } from '@angular/core';
import { Adapter } from "../adapters/adapter";

export class Media {
    constructor(
        public title: string,
        public file: File,
    ) { }
}

export class File {
    constructor(
        public url: string,
        public fileName: string,
        public contentType: string,
    ) { }
}

export class CarouselData {
  id?: string;
  text: string;
  dataMerge?: number;
  width?: number;
  dotContent?: string;
  src?: string;
  dataHash?: string;
  image?:string;
}

export const options = {
  renderNode: {
    'embedded-asset-block': (node: any) => {
      let mediaAdapter:MediaAdapter = new MediaAdapter();
      let img:Media = mediaAdapter.adapt(node.data.target.fields);
      const imgTag = `<img class="img-fluid" src="${img.file.url}" alt="${img.title}"/>`;
      return imgTag;
    }
  }
}

@Injectable({
    providedIn: 'root'
})
export class MediaAdapter implements Adapter<Media> {

  adapt(item: any): Media {
    return new Media(
      item.title,
      item.file,
    );
  }
}