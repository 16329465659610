<div class="solutions container pt-lg-5 p-0">
    <mat-card class="solutions__wrapper container col-lg-12 p-lg-5 flex-column" *ngIf="currentSolution">
      <mat-card-header class="m-0 d-flex">
        <mat-card-title class="d-flex flex-row align-items-center">
          <span class="mr-3" [style.mask]="'url(' + currentSolution.icon.file.url + ')'" [style.-webkit-mask]="'url(' + currentSolution.icon.file.url + ')'" *ngIf="currentSolution.icon"></span>
          {{currentSolution.category}}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="px-2">
        <div class="row">
          <div class="col-lg-12 pb-lg-5 mb-lg-5">
            <div class="pt-lg-0 pt-0" [innerHTML]="currentSolution.longDescription"></div>
          </div>
          <div class="col-lg-4" *ngIf="currentSolution.solution">
            <h2 class="mat-headline">Soluciones</h2>
            <div class="inner_solutions" *ngFor="let solution of currentSolution.solution">
              <img src={{solution.teaserImage.file.url}}
                *ngIf="solution.teaserImage" />
            </div>
          </div>
          <div class="col-lg-8" *ngIf="currentSolution.services">
            <app-services [services]="currentSolution.services" [serviceTitle]="'Servicios'"></app-services>
          </div>
        </div>
        <div class="mt-4 mt-lg-2 text-right more">
          <!-- more -->
          <h5 class="display-5 justify-content-end mb-lg-0">
            <a routerLink="{{'/product-services/' + currentSolution.slug}}" class="d-flex" *ngIf="currentSolution.slug">
              <!-- Conoce todos nuestros productos y soluciones -->
              {{message}}
              <span class="material-icons ml-2">
                keyboard_arrow_right
              </span>
            </a>
          </h5>
        </div>
        <!-- more -->
      </mat-card-content>
    </mat-card>
  </div>