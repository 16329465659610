import { ContentService } from './content.service';
import { CategorySolutions, CategorySolutionsAdapter } from './../models/category-solutions.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CategorySolutionsService {
  constructor(private contentService: ContentService, private adapter: CategorySolutionsAdapter) { }
  list(): Observable<CategorySolutions[]> {
    return this.contentService.getContent({content_type: 'solutionCategory'}).pipe(
      map(data => data.items.map((item:any) => this.adapter.adapt(item.fields))),
    );
  }
  findBySlug(slug?:string): Observable<CategorySolutions> {
    return this.contentService.getContent({content_type: 'solutionCategory', 'fields.slug': slug}).pipe(
      map(data => data.items.map((item:any) => this.adapter.adapt(item.fields))),
    );
  }
}