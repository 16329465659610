import { Component, OnInit, Input } from '@angular/core';
import { Industries } from 'src/app/models/industries.model';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss']
})
export class IndustryComponent implements OnInit {
  @Input('industry') currentIndustry:Industries;
  @Input() message:string;
  constructor(
  ) { }

  ngOnInit(): void {
  }

}
