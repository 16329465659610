<ng-container *ngIf="items">
  <owl-carousel-o [options]="customOptions" class="d-flex carousel">
      <ng-template carouselSlide [id]="item.id" *ngFor="let item of items;let i = index">
        <div class="position-relative overflow-hidden bg-light mat-typography">
          <div class="opacity"></div>
          <div class="highlight__container highlight__container d-flex" [style.background]="'url(' + (item.typeObject === 'image' ? item.highlightsObject.file.url : '') + ')'" [ngClass]="{home: home}">
            <ng-container *ngIf="item.typeObject=== 'video'">
                <video autoplay muted loop>
                  <source src={{item.videoSource.file.url}} type="video/mp4">
                Your browser does not support the video tag.
                </video>
            </ng-container>
            <div class="container d-flex">
              <div class="col-md-7 p-lg-0 my-auto px-lg-3 px-4 highlight__content">
                <div class="highlight__container-title">
                  <h2>{{item.headerSectionTeaserLabel}}</h2>
                </div>
                <div class="highlight__container-title" [innerHTML]="item.headerSectionShortDesc"></div>
                <button mat-raised-button color="primary" class="mt-lg-4 mt-0 text-uppercase py-1" [ngx-scroll-to]="'contact'" *ngIf="item.buttonContact">Contáctanos</button>
              </div><!-- / col-md-7 p-lg-5 my-5-->
            </div><!-- /container -->
          </div>
        </div><!-- /position-relative overflow-hidden bg-light -->
      </ng-template>
  </owl-carousel-o>
</ng-container>