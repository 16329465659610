import { Media, MediaAdapter } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class MediaAppearances {
    constructor(
        public title: string,
        public description: string,
        public medios: Media[]
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class MediaAppearancesAdapter implements Adapter<MediaAppearances> {
  adapt(item: any): MediaAppearances {
    let mediaAdapter = new MediaAdapter();
    return new MediaAppearances(
      item.title,
      item.description,
      item.medios.map((medios: any) => mediaAdapter.adapt(medios.fields)),
    );
  }
}