import { SuccessStoriesService } from './../services/success-stories.service';
import { SolutionsService } from './../services/solutions.service';
import { ShareDataService } from './../services/share-data.service';
import { PageProductServices } from 'src/app/models/page-product-services.model';
import { PagePartnersService } from './../services/page-partners.service';
import { PageIndustriesService } from './../services/page-industries.service';
import { PageHomeService } from 'src/app/services/page-home.service';
import { PageCareersService } from './../services/page-careers.service';
import { PageAboutUsService } from 'src/app/services/page-about-us.service';
import { IndustriesService } from './../services/industries.service';
import { EmailService } from './../services/email.service';
import { ContentService } from './../services/content.service';
import { ConfigurationService } from './../services/configuration.service';
import { CategorySolutionsService } from './../services/category-solutions.service';
import { HttpClientModule } from '@angular/common/http';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { CookieService } from 'ngx-cookie-service';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DeviceDetectorModule } from 'ngx-device-detector';


@NgModule({
    declarations   : [],
    imports        : [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollToModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        CarouselModule,
        HttpClientModule
    ],
    exports        : [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollToModule,
        CarouselModule,
        DeviceDetectorModule
    ],
    providers      : [
        CookieService,
        // CategorySolutionsService,
        // ConfigurationService,
        // ContentService,
        // EmailService,
        // IndustriesService,
        // PageAboutUsService,
        // PageCareersService,
        // PageCareersService,
        // PageHomeService,
        // PageIndustriesService,
        // PagePartnersService,
        // PageProductServices,
        // ShareDataService,
        // SolutionsService,
        // SuccessStoriesService,
    ]
})

export class SharedModule
{

}