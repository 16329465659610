<div id="content">
  <!-- / Progress Bar -->
  <ng-container>
    <mat-progress-bar *ngIf="showProgressBar" color="primary" mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <!-- / Progress Bar -->
  <ng-container>
    <ng-container *ngIf="partnersData">
      <app-highlights [highlights]="partnersData.highlightsHead" [home]="false"></app-highlights>
    </ng-container>
  </ng-container>
  <section class="industries__title mt-5 title__wrapper" *ngIf="partnersData"><!-- title__wrapper -->
    <div class="px-lg-5 pt-lg-5 pb-lg-0 mt-lg-0 mt-4 text-center mat-typography container">
      <div class="title__wrapper title__wrapper-black">
        <h2 class="title__wrapper-title">{{partnersData.industriesServicesOceanLabel}}</h2>
      </div>
    </div>
  </section>
  <!-- title__wrapper -->
  <ng-container *ngIf="partnersData">
    <app-industries [link]="false" [industries]="partnersData.partnersIndustriesOceanServices" [inline]="true"></app-industries>
  </ng-container><!-- insdustries -->
  <section class="partners mat-typography pt-5 mt-lg-0 mt-5" *ngIf="partnersData">
      <div class="container partners__container">
          <div class="title__wrapper title__wrapper-white">
            <h2 class="title__wrapper-title">{{partnersData.whoAreOutPartners}}</h2>
          </div>
            <ul class="p-0 pb-3 m-lg-0 mt-4 row mb-0">
                <li class="d-flex col-lg-3 col-6 align-content-center justify-content-center py-lg-5 py-2" *ngFor="let items of partnersData.logoPartners">
                    <img src={{items.file.url}} class="img-fluid p-lg-0 p-2" *ngIf="items.file" />
                </li>
            </ul>
      </div>
  </section>
  <section class="bg-gris pt-lg-1 mt-lg-0 mt-0" *ngIf="partnersData">
    <app-owners [businessAreas]="partnersData.businessAreas"
      [selector]="true"
      [subtituloRepresentantes]="'Agenda una reunión con uno de nuestros representantes'"
      [tituloRepresentantes]="partnersData.doYouWantBeAPartnerLabel">
    </app-owners>
  </section>
  
  <section class="title__wrapper title__wrapper-blue pb-5 transformamos__title mt-lg-0" *ngIf="partnersData">
    <div class="p-lg-5 text-center mat-typography container w-80">
      <h2 class="title__wrapper-title w-75 text-center mx-auto">{{partnersData.contactSectionTitle}}</h2>
    </div>
  </section>
  <ng-container>
    <div class="container">
      <app-contact [negativo]="true"></app-contact>
    </div>
  </ng-container>
  <div class="nosotros container mt-5 mb-lg-0 mb-5 pt-lg-5" *ngIf="partnersData">
    <div class="title__wrapper title__wrapper-red">
      <h2 class="mb-lg-5 title__wrapper-title w-75 mx-auto text-center">{{partnersData.contactSectionContactSubtitle}}</h2>
    </div>
  </div>
</div>