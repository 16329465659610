import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../scss/styles.css']
})
export class AppComponent {
  title = 'Entel Ocean';
}
