<div class="industries pt-lg-0 pt-4 mat-typography" [class.inline]="inline === true">
  <div class="industries__container px-lg-5 pt-lg-5 container">
    <div class="row">
      <ng-container *ngFor="let items of industries; let i = index;let odd = odd;">
        <a (click)="showData(items, odd);" class="col-6 col-lg-{{columnas}}" [style.cursor]="full ? 'pointer': ''" [style.order]="!isDesktopDevice ? i+1 : ''">
          <div class="industry p-0 pb-lg-0 px-lg-4 pt-lg-4 text-center">
            <img class="mb-lg-4 mb-2 img-fluid" *ngIf="items.image" src={{items.image.file.url}}
              alt="Generic placeholder image" width="120" height="120">
            <h5 class="industry-title" [class.active]="currentIndustry && items.title === currentIndustry.title">{{items.title}}</h5>
          </div>
          <div class="triangle" [class.d-block]="currentIndustry === items" *ngIf="full"></div>
        </a>
        <ng-container *ngIf="isDesktopDevice === false && full && currentIndustry === items">
          <app-industry [industry]="currentIndustry" [style.order]="odd ? i+2 : i+3" [ngClass]="{'odd' : odd, 'even': !odd}"></app-industry>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="full && isDesktopDevice">
        <app-industry [industry]="currentIndustry"></app-industry>
      </ng-container>
    </div><!-- row -->
  </div><!-- industries__container -->
  <div class="mt-lg-0 mt-4 mb-lg-3 text-right more container pb-lg-1" *ngIf="link">
    <h5 class="display-5 justify-content-end d-flex">
      <a routerLink="/industries" class="d-flex">
        Conoce todos nuestros productos y soluciones
        <span class="material-icons ml-2">
          keyboard_arrow_right
          </span>
      </a>
    </h5>
  </div>
</div><!-- industries -->
