<footer class="footer d-flex justify-content-center flex-column pb-lg-5 pb-3"
  [style.background]="'url(' + configurationData.backgroundFooter.file.url + ')'" *ngIf="configurationData">
  <mat-toolbar class="container footer__wrapper align-self-center mt-4 align-items-stretch">
    <div class="d-flex flex-fill flex-lg-column flex-column-reverse justify-content-around mx-4 position-relative">
      <p class="mt-lg-n2">
        {{configurationData.copyright}}
      </p>
      <div class="logo position-absolute">
        <img src="/assets/logo.svg" width="100" class="" />
      </div>
    </div>
    <div class="col-lg-4 d-flex flex-lg-column flex-column justify-content-around linea pt-2"
      *ngIf="configurationData.network">
      <div class="rrss d-flex flex-lg-row flex-column align-items-center mb-lg-5">
        <h3 class="mr-lg-3">Síguenos en </h3>
        <ul class="d-flex flex-row m-lg-0 my-3">
          <li class="mr-lg-1 mr-4" *ngFor="let social of configurationData.network">
            <a href={{social.linkSocialMedia}} target="_blank">
              <div class="icon rounded-circle" [style.mask]="'url(' + social.socialIcon.file.url + ')'"
                [style.-webkit-mask]="'url(' + social.socialIcon.file.url + ')'" *ngIf="social.socialIcon"></div>
            </a>
          </li>
        </ul>
      </div>
      <div class="politicas mt-1">
        <a href="{{configurationData.politicLink}}" target="_blank">PÓLITICAS DE PRIVACIDAD</a>
      </div>
    </div>

  </mat-toolbar>
  <div [ngx-scroll-to]="'menu-component'" class="btn-top-container" [ngClass]="(showBtn)?'entrada':'salida'">
    <img src="/assets/icons/btotop.svg" />
  </div>

</footer>