<div class="hero404 d-flex align-middle flex-column justify-content-md-center">
  <div class="container">
    <h1 class="hero404-title">Error 404</h1>
    <p class="hero404-description">Página no encontrada</p>
  </div>
</div>

<div class="container-404">
  <img src="/assets/icons/compass.svg" class="mb-4" />
  <p>
    Lo sentimos, no hemos podido encontrar la página que buscas
  </p>
</div>