import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Industries } from 'src/app/models/industries.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IndustriesComponent implements OnInit {
  @Input() industries: Industries[];
  @Input() currentIndustry: Industries;
  @Input() link: boolean = true;
  @Input() inline: boolean = false;
  @Input() full: boolean = false;
  @Input() message: string = null;
  isDesktopDevice: boolean = true;
  columnas = 4;
  currentRow = 0;
  constructor(
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.isDesktopDevice = this.deviceService.isDesktop();
    this.columnas = this.inline ? (12 / this.industries.length) : 4;
    console.log(this.message);

  }

  showData(item: Industries, i: number) {
    this.currentRow = this.industries.length / i;
    this.currentIndustry = item;
  }

}
