import { Owners } from './../../models/owners.model';
import { BusinessArea } from './../../models/business-area.model';
import { PagePartnersService } from './../../services/page-partners.service';
import { PagePartners } from './../../models/page-partners.model';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

const pageId = '1Z7sxdWf6yeU4qczmbjyGz';
@Component({
  selector: 'app-page-partners',
  templateUrl: './page-partners.component.html',
  styleUrls: ['./page-partners.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagePartnersComponent implements OnInit {
  showProgressBar:boolean = false;
  partnersData:PagePartners;
  currentBusinessArea: BusinessArea;
  currentOwner: Owners;
  showContact = false;
  isDesktopDevice:boolean = true;


  constructor(
    private pagePartnersService:PagePartnersService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.pagePartnersService.getPage(pageId).subscribe((partners: PagePartners) => {
      this.partnersData = partners;
      this.currentBusinessArea = this.partnersData.businessAreas[0];
    })
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  selectBusiness(selectedBusiness: BusinessArea) {
    this.currentBusinessArea = selectedBusiness;
  }

  onSubmit(){

  }

  agendarReunion(owner:Owners) {
    this.showContact = true;
    this.currentOwner = owner;
  }

  closeAgendarReunion(event) {
    this.showContact = false;
    this.currentOwner = null;
    
    
  }

}
