import { IndustriesComponent } from './industries/industries.component';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../internals/shared.module';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { SuccessComponent } from './success/success.component';
import { ContactComponent } from './contact/contact.component';
import { ScheludeContactFormComponent } from './schelude-contact-form/schelude-contact-form.component';
import { CategorySolutionsComponent } from './category-solutions/category-solutions.component';
import { OwnersComponent } from './owners/owners.component';
import { ServicesComponent } from './services/services.component';
import { SolutionComponent } from './solution/solution.component';
import { IndustryComponent } from './industry/industry.component';

@NgModule({

  imports: [
    RouterModule,
    SharedModule
  ],

  declarations: [
    MenuComponent,
    FooterComponent,
    HighlightsComponent,
    IndustriesComponent,
    SuccessComponent,
    ContactComponent,
    ScheludeContactFormComponent,
    CategorySolutionsComponent,
    OwnersComponent,
    ServicesComponent,
    SolutionComponent,
    IndustryComponent,
  ],

  exports: [
    MenuComponent,
    FooterComponent,
    HighlightsComponent,
    IndustriesComponent,
    SuccessComponent,
    ContactComponent,
    ScheludeContactFormComponent,
    CategorySolutionsComponent,
    OwnersComponent,
    ServicesComponent,
    SolutionComponent,
    IndustryComponent,
  ]

})
export class MainModule { }