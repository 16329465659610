import { PageIndustries, PageIndustriesAdapter } from './../models/page-industries.model';

import { ContentService } from './content.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PageIndustriesService {
  constructor(private contentService: ContentService, private adapter: PageIndustriesAdapter) { }
  getPage(pageId?:string): Observable<PageIndustries> {
    return this.contentService.getEntry(pageId).pipe(
      map(data => this.adapter.adapt(data.fields)));
  }
}