import { PagePartners, PagePartnersAdapter } from './../models/page-partners.model';
import { ContentService } from './content.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PagePartnersService {
  constructor(private contentService: ContentService, private adapter: PagePartnersAdapter) { }
  getPage(pageId?:string): Observable<PagePartners> {
    return this.contentService.getEntry(pageId).pipe(
      map(data => this.adapter.adapt(data.fields)));
  }
}