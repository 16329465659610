import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private _http: HttpClient) { }

  sendMessage(body: any) {
    return this._http.post(`${environment.emailConfig.server}/send-email`, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': environment.emailConfig.apiKey
      }
    });
  }
}
  