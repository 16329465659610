import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class SuccessStories {
    constructor(
        public title: string,
        public longDescription: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class SuccessStoriesAdapter implements Adapter<SuccessStories> {

  adapt(item: any): SuccessStories {
    return new SuccessStories(
      item.title,
      item.longDescription,
    );
  }
}