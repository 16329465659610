import { CategorySolutions } from 'src/app/models/category-solutions.model';
import { PageProductServiceService } from './../../services/page-product-service.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageProductServices } from 'src/app/models/page-product-services.model';
const pageId = '5hJI7Z95HqXkiMyzyllxMx';

@Component({
  selector: 'app-page-productos-servicios',
  templateUrl: './page-productos-servicios.component.html',
  styleUrls: ['./page-productos-servicios.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageProductosServiciosComponent implements OnInit {
  productServiceData: PageProductServices;
  showProgressBar: boolean = true;
  currentSolution: CategorySolutions = null;
  constructor(
    private pageProductServiceService: PageProductServiceService
  ) { }

  ngOnInit(): void {
    this.pageProductServiceService.getPage(pageId).subscribe((productService: PageProductServices) => {
      this.productServiceData = productService;
      this.showProgressBar = false;
      this.currentSolution = productService.categorySolutions[0];
    })
  }

}
