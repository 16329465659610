import { Media } from './common.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class OurPhilosophy {
    constructor(
        public title: string,
        public shortDescription: string,
        // public backImage: Media
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class OurPhilosophyAdapter implements Adapter<OurPhilosophy> {

  adapt(item: any): OurPhilosophy {
    return new OurPhilosophy(
      item.title,
      item.shortDescriptions,
      // item.backImage.fields,
    );
  }
}