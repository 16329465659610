import { Industries } from 'src/app/models/industries.model';
import { Owners } from './../../models/owners.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PageIndustries } from './../../models/page-industries.model';
import { PageIndustriesService } from './../../services/page-industries.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
const pageId = '5V8VCAcUyNT5q4F8QMKbmr';
@Component({
  selector: 'app-page-industries',
  templateUrl: './page-industries.component.html',
  styleUrls: ['./page-industries.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageIndustriesComponent implements OnInit {
  industriesData: PageIndustries;
  showProgressBar: boolean = true;
  isDesktopDevice:boolean = true;
  currentOwner: Owners;
  currentIndustry: Industries;
  showContact:boolean = false;
  constructor(
    private pageIndustriesService: PageIndustriesService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.pageIndustriesService.getPage(pageId).subscribe((industries: PageIndustries) => {
      this.industriesData = industries;
      this.showProgressBar = false;
      this.currentIndustry = industries.industries[0];
    })
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  agendarReunion(owner:Owners) {
    this.showContact = true;
    this.currentOwner = owner;
  }

  closeAgendarReunion(event:any) {
    this.showContact = false;
    this.currentOwner = null;
    
    
  }

}
