import { ConfigurationService } from './../services/configuration.service';
import { Configuration } from './../models/configuration.model';
import { Inject, Injectable } from '@angular/core';
const pageId = '34VMV1aXjQ0WegenhXm4nw';
@Injectable()
export class AppConfig {

    private config: Configuration = null;

    constructor(private configurationService: ConfigurationService) {

    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig() {
        return this.config;
    }
    public load() {
        return new Promise((resolve, reject) => {
            this.configurationService.getPage(pageId).subscribe((conf) => {
                this.config = conf;
                resolve(true);
            });
        })
    }
}