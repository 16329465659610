import { CategorySolutions } from 'src/app/models/category-solutions.model';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SolutionComponent implements OnInit {
  @Input() currentSolution: CategorySolutions;
  @Input() message: string;
  constructor() { }

  ngOnInit(): void {
  }

}
