import { AppConfig } from './config/app.config';
import { Configuration } from './models/configuration.model';
import { ShareDataService } from './services/share-data.service';
import { ConfigurationService } from './services/configuration.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { PagesModules } from './pages/pages.module';
import { ContentService } from './services/content.service';
import { SharedModule } from './internals/shared.module';
import { FormsModule } from '@angular/forms';
const initializerConfigFn = (config: AppConfig) => () => config.load();
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    AppRoutingModule,
    PagesModules,
  ],
  providers: [
    ContentService,
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: initializerConfigFn, deps: [AppConfig], multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
