import { Industries, IndustriesAdapter } from './industries.model';
import { Highlights, HighlightsAdapter } from './highlights.model';
import { Injectable } from '@angular/core';
import { Adapter } from '../adapters/adapter';
import { Media, MediaAdapter } from './common.model';
import { BusinessArea, BusinessAreaAdapter } from './business-area.model';

export class PagePartners {
    constructor(
        public highlightsHead: Highlights[],
        public businessAreas: BusinessArea[],
        public contactSectionContactSubtitle: string,
        public contactSectionTitle: string,
        public doYouWantBeAPartnerLabel: string,
        public logoPartners: Media[],
        public whoAreOutPartners: string,
        public industriesServicesOceanLabel: string,
        public partnersIndustriesOceanServices:Industries[]
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PagePartnersAdapter implements Adapter<PagePartners> {

  adapt(item: any): PagePartners {
    let mediaAdapter = new MediaAdapter();
    let businessAreaAdapter = new BusinessAreaAdapter();
    let highlightsAdapter = new HighlightsAdapter();
    let industriesAdapter = new IndustriesAdapter();

    return new PagePartners(
      item.highlightsHead.map((highlight: any) => highlightsAdapter.adapt(highlight.fields)),
      item.businessAreas.map((business: any) => businessAreaAdapter.adapt(business.fields)),
      item.contactSectionContactSubtitle,
      item.contactSectionTitle,
      item.doYouWantBeAPartnerLabel,
      // item.headPartnerImage.fields,
      // item.headSectionTitlePartners,
      item.logoPartners.map((medios: any) => mediaAdapter.adapt(medios.fields)),
      item.whoAreOutPartners,
      item.industriesServicesOceanLabel,
      item.partnersIndustriesOceanServices.map((industries: any) => industriesAdapter.adapt(industries.fields)),
    );
  }
}