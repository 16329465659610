<ng-container *ngIf="services">
    <h2 class="mat" *ngIf="serviceTitle">{{serviceTitle}}</h2>
    <div class="wrapper__services row">
        <div class="inner__services col-lg-4 align-items-center d-flex flex-lg-column flex-row" *ngFor="let service of services">
            <div class="img">
                <img src={{service.teaserImage.file.url}} *ngIf="service.teaserImage" class="img-fluid rounded" />
                <div class="w-50 position-absolute">
                    <h4 class="py-2 px-3">{{service.title}}</h4>
                </div>
            </div>
            <div [innerHTML]="service.longDescription" *ngIf="full"></div>
        </div>
    </div>
</ng-container>